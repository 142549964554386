// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\Users\\Borys\\Desktop\\Programming\\Web\\Gatsby\\portfolio\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\Borys\\Desktop\\Programming\\Web\\Gatsby\\portfolio\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("C:\\Users\\Borys\\Desktop\\Programming\\Web\\Gatsby\\portfolio\\src\\pages\\projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-skills-js": () => import("C:\\Users\\Borys\\Desktop\\Programming\\Web\\Gatsby\\portfolio\\src\\pages\\skills.js" /* webpackChunkName: "component---src-pages-skills-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Users\\Borys\\Desktop\\Programming\\Web\\Gatsby\\portfolio\\.cache\\data.json")

